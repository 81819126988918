@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,500,600,700&display=swap");

.modalExpired-countdown__wrapper {
  display: flex;
  flex-direction: row;
  width: 75%;
  padding: 6px;
  gap: 6px;
  border: 1px solid #bebebe;
  border-radius: 4px;
  margin-bottom: 12px;
}

.modalExpired-countdown__timeout {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.modalExpired-countdown__timer {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  letter-spacing: 0.002em;
  color: #215aa8;
}

.modalExpired-countdown__timerLow {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  letter-spacing: 0.002em;
  color: #fe7070;
}

.modalExpired-countdown__timeoutText {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 26px;
  text-align: start;
  letter-spacing: 0.002em;
}

.modalExpired-countdown__timeoutResend {
  font-family: "Open Sans";
  font-style: normal;
  text-align: start;
}

.modalExpired-title {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.002em;
  color: #215aa8;
  margin-top: 24px;
}

.modalExpired-subtitle {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.002em;
  color: #2d2d2d;
  margin: 12px 18px 0;
}
