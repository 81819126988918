@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,500,600,700&display=swap');

.drawer__box{
  background: #FFFFFF;
  max-width: 440px;
  border-radius: 8px 8px 0 0;
  max-height: 60vh;
}

.drawer__heading {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.002em;
  color: #2D2D2D;
}

.content__heading {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.002em;
  color: #2D2D2D;
}

.drawer__body{
  font-family: 'Open Sans';
  overflow-y: scroll;
  max-height: 60vh;
}