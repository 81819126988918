@import url("https://fonts.googleapis.com/css?family=Poppins:400,500,600");

.main-container {
  background: linear-gradient(to bottom, #ebdfff, #bfc2ff, #c8eef7, #ffffff);
  max-width: 480px;
  margin: auto;
  min-height: 100vh;
  padding: 15px 22px 22px;
  position: relative;
  flex-direction: column;
  font-family: "Poppins", sans-serif;
  z-index: 1;
}

.header-container {
  margin: 3em 0;
}

.header-text-title {
  color: #4c5a7a;
  font-size: 32px;
  font-style: normal;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 38px;
  margin-bottom: 12px;
}

.header-text-subtitle {
  color: #4c5a7a;
  font-size: 12px;
  font-style: normal;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 12px;
}

.membership-level-container {
  background-color: #ffffff;
  max-width: 130px;
  margin: 1em auto;
  gap: 0.5em;
  height: 52px;
  border-radius: 40px;
}

.membership-level-title {
  color: #80807b;
  font-size: 13px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
}

.membership-level-subtitle {
  color: #4c5a7a;
  font-family: Poppins;
  font-size: 8px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: left;
}

.membership-rewards-text {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: 2.5em;
}

.shop-earn-more-container {
  margin: 2em 0;
}

.shop-earn-more-row-top {
  margin-bottom: 24px;
}

.shop-earn-more-row {
  gap: 24px;
  margin-bottom: 24px;
}

.shop-earn-more-box {
  width: 120px;
  height: 160px;
  background: #ffffff;
  border-radius: 12.11px;
  text-align: center;
  padding: 10px;
}

.shop-earn-more-title {
  font-family: Poppins;
  color: #705E99;
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: center;
  padding-bottom: 15px;
}

.shop-earn-more-subtitle {
  font-family: Poppins;
  color: #705E99;
  font-size: 10px;
  font-weight: 300;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: center;
  padding-top: 15px;
}

.point-calculation-container {
  margin: 2em 0;
}

.how-itworks-container {
  margin: 2em 0;
}

.how-itworks-box {
  margin-bottom: 2em;
  margin-left: 2em;
  margin-right: 1em;
  gap: 1.25em;
}

.how-itworks-title {
  color: #4c5a7a;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
}

.how-itworks-subtitle {
  color: #4c5a7a;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
}

.point-calculation-table-header {
  background-color: #5c697f;
  padding: 3px 0 2px 8px;
  border-radius: 4px 4px 0 0;
}

.point-calculation-table-content {
  background-color: #ffffff;
  margin-bottom: 1em;
  margin-left: auto;
  margin-right: auto;
  gap: 0.75em;
  height: 60px;
  padding: 10px;
}

.point-calculation-table-title {
  color: #ffffff;
  font-size: 12px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
}

.point-calculation-table-title2 {
  color: #5c697f;
  font-size: 12px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  font-family: "Poppins", sans-serif;
}

.point-calculation-table-title3 {
  color: #80807b;
  font-size: 12px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
}

.point-calculation-table-subtitle {
  color: #4c5a7a;
  font-family: Poppins;
  font-size: 8px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}

.point-activities-table-header {
  background-color: #5c697f;
  padding: 3px 0 2px 8px;
  border-radius: 4px 4px 0 0;
}

.point-activities-table-content {
  background-color: #ffffff;
  margin-bottom: 2em;
  border-radius: 0 0 4px 4px;
}

.point-activities-table-icon {
  padding: 0.25em;
}

.point-activities-table-subtitle {
  color: #4c5a7a;
  font-family: Poppins;
  font-size: 10px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}

.logo-showcase-container {
  margin: 4em 1.5em;
}

.join-now-color-scheme {
  background-color: #5c697f;
}
