@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,500,600,700&display=swap");

.store-drawer__box{
  background: #FFFFFF;
  max-width: 440px;
  border-radius: 8px 8px 0 0;
  max-height: 60vh;
}

.store-drawer__heading {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.002em;
  color: #2D2D2D;
}

.store-drawer__body{
  overflow-y: scroll;
  max-height: 50vh;
}

.store-drawer__search{
  margin-bottom: 1.25em;
}

.store-radio-wrapper {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  direction: rtl;
}

.store-radio-wrapper input[type="radio"] {
  order: 2;
}

.store-radio-wrapper label {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.002em;
  color: #2D2D2D;
  order: 1;
  justify-content: space-between;
  padding: 1em 0;
}