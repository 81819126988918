@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,500,600,700&display=swap");

.verify__main-container {
  margin: auto;
  max-width: 480px;
  height: 100%;
  position: relative;
  flex-direction: column;
}

.verify__header-container {
  max-height: 15%;
  top: 0;
  padding: 22px 22px 0 22px;
  color: rgba(0, 0, 0, 0.7);
  flex-direction: column;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
}

.verify__submain-container {
  height: 100%;
  position: relative;
  color: rgba(0, 0, 0, 0.7);
  flex-direction: column;
  padding: 22px;
  font-family: "Open Sans", sans-serif;
  justify-content: space-between;
  font-size: 14px;
}

.verify-fields {
  height: 65%;
}

.poppins-font {
  font-family: "Open Sans", sans-serif;
}

.open-font {
  font-family: "Open Sans", sans-serif;
}

.verify__title {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.002em;
  color: #2d2d2d;
}

.verify__subtitle {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 26px;
  letter-spacing: 0.002em;
  color: #2d2d2d;
  margin-top: 25px;
}

.verify-tnc__text-highlight {
  color: #80d9ff;
}

.verify-field__group {
  margin: 10% 0;
}

.verify-field__main {
  display: flex;
  padding: 10px 0;
  width: 100%;
}

.verify-field__input {
  display: flex;
  width: 50%;
}

.verify-field__right-wrapper {
  flex-grow: 1;
  display: flex;
  width: 50%;
  align-content: flex-end;
  text-align: end;
  align-items: flex-end;
}

.verify-field__right-text {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 26px;
  flex-grow: 1;
}

.verify-checkbox__text {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.002em;
  color: #2d2d2d;
  font-family: "Open Sans", sans-serif;
}

.verify-checkbox__detail-text {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.002em;
  text-decoration-line: underline;
  color: #215aa8;
}

.verify-field__title {
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
  font-weight: 500;
  font-family: "Open Sans", sans-serif;
}

.verify-field__error {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 18px;
  letter-spacing: 0.002em;
  margin-top: 1em;
  color: #fe7070;
}

.verify-field__title-wrapper {
  padding-bottom: 0.75em;
}

.verify-countdown__wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 10px;
  gap: 10px;
}

.verify-countdown__timeout {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.verify-countdown__timer {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  letter-spacing: 0.002em;
  color: #215aa8;
}

.verify-countdown__timerLow {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  letter-spacing: 0.002em;
  color: #FE7070;
}

.verify-countdown__timeoutText {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 26px;
  text-align: start;
  letter-spacing: 0.002em;
}

.verify-countdown__timeoutResend {
  font-family: "Open Sans";
  font-style: normal;
  text-align: start;
}

.verify-button__wrapper {
  width: 100%;
  font-family: "Poppins", sans-serif;
  max-height: 25%;
  justify-content: end;
  align-items: end;
  flex-direction: column;
  bottom: 0;
}

.verify-button__continue {
  font-size: 18px;
  font-weight: 400;
  color: #fff;
  background-color: #113A70;
  border-radius: 4px;
  width: 100%;
  font-family: "Open Sans", sans-serif;
  max-height: 15%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  bottom: 0;
  margin-top: 60px;
}
