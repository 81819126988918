@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,500,600,700&display=swap");

.header-container {
  max-height: 15%;
  top: 0;
  color: rgba(0, 0, 0, 0.7);
  flex-direction: column;
  font-family: Open Sans, sans-serif;
  font-size: 14px;
}

.main-container {
  max-width: 480px;
  margin: auto;
  min-height: 100vh;
  position: relative;
  flex-direction: column;
  z-index: 1;
}

.form-container {
  min-height: 75vh;
  position: relative;
  color: rgba(0, 0, 0, 0.7);
  flex-direction: column;
  padding: 15px 22px 22px;
  font-family: "Open Sans", sans-serif;
  justify-content: space-between;
  font-size: 14px;
}

.form-fields {
  height: 65%;
}

.choose-store {
  border-bottom: 1px solid #dad9d9;
  padding: 8px 0;
}

.choose-store__button {
  font-family: "Open Sans", sans-serif;
  width: 100%;
}

.choose-store__button-text {
  text-align: start;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #6e7681;
}

.choose-store__selected {
  text-align: start;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #2d2d2d;
}

.open-font {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
}

.form-tnc__text-highlight {
  color: #80d9ff;
}

.form-field__group {
  padding: 4.75% 0;
}

.form-captcha__wrapper {
  width: 100%;
  margin: auto;
  justify-content: end;
  align-items: end;
  flex-direction: column;
  bottom: 0;
  z-index: 0;
}

.form-button__wrapper {
  width: 100%;
  font-family: "Open Sans", sans-serif;
  max-height: 25%;
  justify-content: end;
  align-items: end;
  flex-direction: column;
  bottom: 0;
}

.form-button__continue {
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  background-color: #113a70;
  border-radius: 4px;
  width: 100%;
  font-family: "Open Sans", sans-serif;
  max-height: 15%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  bottom: 0;
}

.form-checkbox__text {
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.002em;
  color: #2d2d2d;
  font-family: "Open Sans", sans-serif;
}

.form-checkbox__detail-text {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.002em;
  text-decoration-line: underline;
  color: #215aa8;
}

.form-field__title {
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0;
  font-weight: 500;
  font-family: "Open Sans", sans-serif;
}

.form-field__error {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 18px;
  letter-spacing: 0.002em;
  margin-top: 0.25em;
  color: #fe7070;
}

.form-field__title-wrapper {
  padding-bottom: 0.25em;
}
