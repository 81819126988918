.success__main-container {
  margin: auto;
  max-width: 480px;
  min-height: 100vh;
  position: relative;
  display: flex;
  align-items: center;
  align-content: center;
  background: #113A70;
  flex-direction: column;
}

.success__submain-container {
  margin: auto;
  display: flex;
  position: relative;
  align-items: center;
  align-content: center;
  flex-direction: column;
}

.success__register-title {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  margin: 28px 0 16px 0;
  color: #ffffff;
}

.success__register-subtitle {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  margin: 0 49px 60px;
  color: #ffffff;
}

.success__button-text {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #113a70;
}
